import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
    ${reset}
    body{
        box-sizing: border-box;
        font-family: 'Raleway', sans-serif;
        cursor: default;
        a {
            text-decoration: none;
            cursor: pointer;
            font-weight: 600;
            color: #052a7b;
        }

       
    }
    html,body{
        height: 100vh;
       background: rgb(19,20,22);
       background: linear-gradient(180deg, rgba(19,20,22,0.9808298319327731) 26%, rgba(61,65,69,1) 50%, rgba(94,98,102,1) 100%);
    }

    body::-webkit-scrollbar {
         display: none;
    }


    body {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    }

    .root{
        height: 100%;
    }
`;

export default GlobalStyle;
