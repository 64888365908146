import styled from "styled-components";

export default function HowItWorks() {
  return (
    <OutterContainer>
      <main>
        <FirstSide>
          <span>
            <h1>
              {" "}
              <nav className="firstMessage">How It Works </nav>{" "}
            </h1>
          </span>
          <div>
            <p>
              Our process is straightforward: When you reach out to us through
              WhatsApp or Telegram, we swiftly connect with trusted IT
              professionals worldwide. We ensure that you have access to
              reliable experts who can meet your IT requirements with
              excellence. Your technology solutions start here.
            </p>
          </div>
        </FirstSide>

        <SecondSide>
          <span>
            <img src="img/howitworks.png" alt="homepage" />
          </span>
        </SecondSide>
      </main>
    </OutterContainer>
  );
}

const OutterContainer = styled.div`
  height: 100%;
  margin-top: 8px;
  padding-bottom: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  background: rgb(19, 20, 22);
  background: linear-gradient(
    180deg,
    rgba(19, 20, 22, 0.9808298319327731) 26%,
    rgba(61, 65, 69, 1) 50%,
    rgba(94, 98, 102, 1) 100%
  );

  main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const FirstSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-content: stretch;
  word-wrap: wrap;
  word-break: break-word;
  gap: 35px;
  padding: 20px 20px 20px 70px;

  @media screen and (max-width: 508px) {
    padding: 20px 20px 20px 40px;
  }

  span {
    width: 100%;
    word-break: break-word;
    padding-top: 50px;

    h1 {
      font-size: 48px;
      font-weight: 700;
      font-family: "Roboto", sans-serif;
      color: #fcffff;
      opacity: 0.9;
      cursor: pointer;

      .firstMessage {
        padding-bottom: 10px;
        color: #f7c120;
      }

      firstMessage:hover {
        color: #ffd000;
      }
    }

    h1:hover {
      opacity: 1;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    //  max-width: 500px;
    width: 100%;
    gap: 20px;

    p {
      word-break: break-word;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.5;
      font-family: "Roboto", sans-serif;

      color: #fcffff;

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 400px;
      width: 100%;
      gap: 20px;
    }
  }
`;

const SecondSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80%;
  word-wrap: wrap;
  word-break: break-word;

  span {
    padding: 25px;

    @media screen and (max-width: 800px) {
      padding: 0;
    }
  }
  img {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.25);

    @media screen and (min-width: 1800px) {
      width: 600px;
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
