import styled from "styled-components";
import { SocialIcon } from "react-social-icons";

export default function HomePage() {
  const handleClick = () => {
    const phoneNumber = "+4793698649";
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);

    const message =
      "Hi there! I'm interested in learning more about your company.";
    const encodedMessage = encodeURIComponent(message);

    const whatsappLink = `https://wa.me/${encodedPhoneNumber}/?text=${encodedMessage}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <OutterContainer>
      <main>
        <FirstSide>
          <span>
            <h1>
              {" "}
              <nav className="firstMessage">Welcome to Carlos Gabriel </nav> C.R
              Limited{" "}
            </h1>
          </span>
          <div>
            <p>
              Carlos Gabriel C.R Limited is your premier destination for IT
              service intermediation. We specialize in connecting you with the
              right IT professionals, whether you require expertise in
              cybersecurity, software engineering, or any other IT service.
              Contact us to find the perfect match for your needs, or schedule a
              mentoring or consulting session to discover the ideal IT solution.
            </p>
          </div>

          <div onClick={handleClick}>
            <nav className="whatsapp">
              <SocialIcon
                url="https://web.whatsapp.com"
                fgColor="#ffffff"
                target="_blank"
              />

              <h2>
                Click here to contact us <br /> <span>On WhatsApp</span>{" "}
              </h2>
            </nav>
          </div>

          <div>
            <a href="https://t.me/LiberdadeAgoristaa" target="_blank">
              <nav className="telegram">
                <SocialIcon
                  url="https://web.telegram.org/a/"
                  fgColor="#ffffff"
                  target="_blank"
                />

                <h2>
                  Click here to connect with us <br /> <span> On Telegram</span>{" "}
                </h2>
              </nav>
            </a>
          </div>
        </FirstSide>

        <SecondSide>
          <span>
            <img src="img/CarlosGabriel.png" alt="homepage" />
          </span>
        </SecondSide>
      </main>
    </OutterContainer>
  );
}

const OutterContainer = styled.div`
  height: 100%;
  margin-top: 8px;
  padding-bottom: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  background: rgb(19, 20, 22);
  background: linear-gradient(
    180deg,
    rgba(19, 20, 22, 0.9808298319327731) 26%,
    rgba(61, 65, 69, 1) 50%,
    rgba(94, 98, 102, 1) 100%
  );

  main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const FirstSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-content: stretch;
  word-wrap: wrap;
  word-break: break-word;
  gap: 35px;
  padding: 20px 20px 20px 70px;

  @media screen and (max-width: 508px) {
    padding: 20px 40px 20px 40px;
  }

  span {
    width: 100%;
    word-break: break-word;
    padding-top: 50px;

    h1 {
      font-size: 48px;
      font-weight: 700;
      font-family: "Roboto", sans-serif;
      color: #fcffff;
      opacity: 0.9;
      cursor: pointer;

      .firstMessage {
        padding-bottom: 10px;
        color: #f7c120;
      }

      .firstMessage:hover {
        color: #ffd000;
      }
    }

    h1:hover {
      opacity: 1;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    //  max-width: 500px;
    width: 100%;
    gap: 20px;

    p {
      word-break: break-word;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.5;
      font-family: "Roboto", sans-serif;

      color: #fcffff;

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 400px;
      width: 100%;
      gap: 20px;
    }

    .whatsapp {
      max-width: 400px;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 50px;
      background-color: #68bb63;
      border: 2px solid #5a5956;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
      word-break: break-word;
      border-radius: 20px;
      padding-left: 18px;
      cursor: pointer;

      @media screen and (max-width: 395px) {
        gap: 20px;
        padding-left: 10px;
      }

      @media screen and (max-width: 320px) {
        gap: 12px;
      }

      @media screen and (max-width: 290px) {
        gap: 2px;
      }

      h2 {
        font-size: 16px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: #fcffff;
        line-height: 1.4;
        opacity: 0.9;

        @media screen and (max-width: 363px) {
          font-size: 13px;
        }

        @media screen and (max-width: 290px) {
          font-size: 11px;
        }

        span {
          padding-left: 5px;
          font-size: 22px;
          font-weight: 700;

          @media screen and (max-width: 290px) {
            font-size: 18px;
          }
        }
      }
    }

    .whatsapp:hover {
      background-color: #50f55e;
    }

    .telegram {
      max-width: 400px;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 50px;
      background-color: #2f81bd;
      border: 2px solid #5a5956;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
      word-break: break-word;
      border-radius: 20px;
      padding-left: 18px;
      cursor: pointer;

      @media screen and (max-width: 395px) {
        gap: 20px;
      }

      @media screen and (max-width: 320px) {
        gap: 4px;
        padding-left: 10px;
        padding-right: 5px;
      }

      h2 {
        font-size: 16px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: #fcffff;
        line-height: 1.4;
        opacity: 0.9;

        @media screen and (max-width: 363px) {
          font-size: 13px;
        }

        @media screen and (max-width: 290px) {
          font-size: 11px;
        }

        span {
          padding-left: 5px;
          font-size: 22px;
          font-weight: 700;

          @media screen and (max-width: 290px) {
            font-size: 18px;
          }
        }
      }
    }

    .telegram:hover {
      background-color: #5aa3d4;
    }
  }
`;

const SecondSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80%;
  word-wrap: wrap;
  word-break: break-word;

  span {
    padding: 25px;

    @media screen and (max-width: 800px) {
      padding: 0;
    }
  }
  img {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.25);

    @media screen and (min-width: 1800px) {
      width: 600px;
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
