import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Top() {

  return (
    <Main>
        <nav> 
      <div>
       
        <Link to={`/`}>
          <h2 >Home</h2>
        </Link>
        <Link to={`/how-it-works`}>
          <h2><nobr>  How it works </nobr></h2>
        </Link>
        <Link to={`/global-focus`}>
          <h2><nobr> Global Focus </ nobr></h2>
        </Link>
        </div>
     
      </nav>
    </Main>
  );
}

const Main = styled.main`
  width: 100%;
  height: 72px;
 // background: #20374B;
 background: rgb(19,20,22);
background: linear-gradient(360deg, rgba(19,20,22,0.9808298319327731) 26%, rgba(61,65,69,1) 50%, rgba(94,98,102,1) 100%);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  nav{
    width: 90%;
    display: flex;
    align-items: center;
   justify-content: center;
   flex-wrap: wrap;
  // background-color: yellow;
  }

  div {
    width: 100%;
    padding: 0 25px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
   // padding-bottom: 10px;
 // background-color: red;

   @media screen and (max-width: 415px) {
    gap: 18px;
    }

    @media screen and (max-width: 300px) {
    gap: 10px;
    }
  }
  

  h2 {
    font-size: 25px;
    font-weight: 400;
    color: #fefefe;
    font-family: 'Roboto', sans-serif;
    border-bottom: 2px solid #E4CC64;
    padding-bottom: 5px;
    opacity: 0.9;
    //padding: 0 5px 0 5px;


    @media screen and (max-width: 550px) {
      font-size: 18px;
    }

    @media screen and (max-width: 300px) {
      font-size: 15px;
    }
  }

  h2:hover {
   opacity: 0.7;
}
`;